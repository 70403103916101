<script setup>
import { inject } from "vue";
import VueTypes from "vue-types";

const displayWidth = inject("displayWidth");
const getLogDatetime = inject("getLogDatetime");

defineProps({
  current: VueTypes.bool.def(false),
  unread: VueTypes.bool.def(false),
  typing: VueTypes.bool.def(false),
  chatId: VueTypes.string.required,
  avatar: VueTypes.string.required,
  date: VueTypes.string.required,
  userId: VueTypes.number.required,
  senderId: VueTypes.number.required,
  receiverName: VueTypes.string.required,
  senderName: VueTypes.string.required,
  message: VueTypes.string.required,
});
defineEmits(["is:click-detail"]);

const stripMessage = (message) => {
  return message.replace(/(<([^>]+)>)/gi, "");
};
</script>

<template>
  <div
    class="chat-list-item"
    :class="{ 'unread-chat': unread, 'current-chat': current }"
  >
    <a-row
      justify="space-between"
      class="chat-list-item-row"
      @click="$router.push('/chats/' + chatId)"
    >
      <a-col
        :xs="4"
        :sm="2"
        :md="2"
        :lg="24"
        :xl="4"
        :xxl="4"
        class="d-flex justify-center align-center"
      >
        <a-avatar :size="40" :src="$cdnAvatarUrl + avatar" />
      </a-col>
      <a-col :xs="17" :sm="18" :md="20" :lg="0" :xl="16" :xxl="16">
        <div class="chat-list-item-content">
          <div class="chat-list-item-username is-heading">
            {{ +userId == senderId ? receiverName : senderName }}
          </div>
          <div
            class="chat-list-item-message word-cut icon-color"
            :class="{ 'typing-chat': typing }"
            v-html="typing ? 'yazıyor..' : stripMessage(message)"
          ></div>
        </div>
      </a-col>
      <a-col
        :xs="3"
        :sm="4"
        :md="2"
        :lg="0"
        :xl="4"
        :xxl="4"
        class="d-flex justify-end"
      >
        <div class="chat-list-item-datetime-wrapper">
          <span class="text-light lg-d-none icon-color chat-date">{{
            getLogDatetime(date)
          }}</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<style lang="scss">
.chat-list-item {
  border-radius: 5px;
  padding: 0.5rem;
  transition: 0.3s;
  cursor: pointer;
  margin-bottom: 3px;
  &.current-chat {
    border-left: 5px solid var(--theme-primary-color);
    background: linear-gradient(
      to left,
      transparent,
      transparent,
      transparent,
      transparent,
      transparent,
      var(--theme-secondary-button-background)
    );
  }
  &.unread-chat {
    background: var(--default-chat-list-item-unread-background);
  }
  .chat-list-item-row {
    align-items: stretch;
  }
  .chat-list-item-username {
    font-size: 0.9375rem;
  }
  .chat-date {
    font-size: 0.6875rem;
  }
  .chat-list-item-datetime-wrapper {
    height: 100%;
    display: flex;
    align-items: flex-start;
  }
  &:hover {
    padding-left: 1rem;
    background-color: var(--default-chat-list-item-hover-background);
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .chat-list-item {
    &.current-chat {
      border-left: 0;
      border-top: 5px solid var(--theme-primary-color);
      background: linear-gradient(
        to top,
        transparent,
        var(--theme-secondary-button-background)
      );
    }
  }
}
</style>
