<script setup>
import { computed, reactive, defineAsyncComponent } from "vue";

const ChatCreateModal = defineAsyncComponent(() =>
  import("@/features/chat/modal/create/ChatCreateModal.vue")
);

const props = defineProps({
  searchKey: {
    type: String,
    required: true,
  },
});
const emit = defineEmits([
  "update:searchKey",
  "is:search",
  "is:create",
  "is:clear",
]);

const modalState = reactive({
  visible: false,
});

const searchKey = computed({
  get: () => props.searchKey,
  set: (value) => {
    emit("update:searchKey", value);
    if (value.length > 0) {
      emit("is:search", value);
    } else {
      emit("is:clear");
    }
  },
});
</script>

<template>
  <div class="chat-list-search lg-d-none">
    <a-input
      size="large"
      placeholder="Sohbet Arayın"
      class="no-outline"
      v-model:value="searchKey"
      :bordered="false"
    >
      <template #addonBefore>
        <i class="bx bx-search font-size-135 icon-color"></i>
      </template>
      <template #addonAfter>
        <a-tooltip title="Yeni Sohbet Oluştur">
          <i
            class="bx bx-user-plus font-size-135 icon-color cursor-pointer"
            @click="modalState.visible = true"
          ></i>
        </a-tooltip>
      </template>
    </a-input>
    <chat-create-modal
      v-if="modalState.visible"
      v-model:visible="modalState.visible"
      @is:create="$emit('is:create', $event)"
    ></chat-create-modal>
  </div>
</template>
