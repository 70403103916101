<script setup>
import { inject } from "vue";

const logout = inject("logout");
const displayWidth = inject("displayWidth");
</script>

<template>
  <div class="chat-list-head lg-d-none">
    <div class="chat-list-head-left">
      <h2 class="font-size-150 app-title">İtemSatış</h2>
      <small class="app-subtitle"
        >Sohbet Uygulaması
        <span class="app-version">BETA</span>
      </small>
    </div>
    <div class="chat-list-head-right">
      <a-tooltip title="Çıkış Yap" v-if="displayWidth < 1200">
        <a @click="logout">
          <i class="bx bx-log-out font-size-180 icon-color"></i>
        </a>
      </a-tooltip>
    </div>
  </div>
</template>

<style lang="scss">
.chat-list-head {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  .chat-list-head-right {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .chat-list-head-left {
    display: flex;
    flex-direction: column;
    .app-title {
      color: var(--theme-primary-color-extra-light);
      line-height: 1rem;
      font-weight: 600 !important;
      margin-top: 0.9rem;
      margin-bottom: 0.1rem !important;
    }
    .app-subtitle {
      font-size: 0.8rem;
      font-weight: 400 !important;
      color: var(--theme-gray-color-light);
      .app-version {
        font-size: 0.6rem;
        background: var(--app-version-gradient);
        padding: 0 0.25rem;
        border-radius: 3px;
        letter-spacing: 0.04rem;
        text-shadow: 0.04rem 0.04rem 0.04rem var(--theme-text-shadow-color);
        margin-left: 0.18rem;
        color: var(--theme-text-color-light);
      }
    }
  }
}
</style>
