<script setup>
import { inject } from "vue";
import VueTypes from "vue-types";

const displayWidth = inject("displayWidth");

defineProps({
  searched: VueTypes.bool.def(false),
  searchEmpty: VueTypes.bool.def(false),
  archived: VueTypes.bool.def(false),
  archiveIsVisible: VueTypes.bool.def(true),
});
defineEmits(["is:click-back", "is:click-archive"]);
</script>

<template>
  <div class="chat-state-switch">
    <div class="chat-state-title mb-3">
      <a-button
        v-if="!searched && archived"
        class="secondary-button mr-3"
        @click="$emit('is:click-back')"
      >
        <i class="bx bx-arrow-back font-size-150"></i>
      </a-button>
      <h3 class="is-description no-margin lg-d-none">
        {{
          searched
            ? "Arama Sonuçları"
            : archived
            ? "Arşivlenmiş"
            : "Sohbetlerim"
        }}
      </h3>
    </div>
    <a-empty
      v-if="searched && searchEmpty"
      image="/img/static/search-engine.png"
      class="mt-3"
    >
      <template #description>
        <p class="no-margin is-description">
          Aramanız ile eşleşen bir sohbet bulunamadı
        </p>
      </template>
    </a-empty>
    <a-button
      v-else-if="!archived && archiveIsVisible"
      class="secondary-button"
      size="large"
      block
      @click="$emit('is:click-archive')"
    >
      {{
        displayWidth > 1200 || displayWidth < 992
          ? "Arşivlenmiş sohbetler"
          : "Arşiv"
      }}
    </a-button>
  </div>
</template>

<style lang="scss">
.chat-state-switch {
  margin-top: 1.25rem;
  .chat-state-title {
    display: flex;
    align-items: center;
  }
}
</style>
